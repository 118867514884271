<template>
    <div style="background: #f5f5f5;">
        <div class="ep-item">
            <div class="block2 flex-col">
                <span class="paragraph1">国家高新<br/>技术企业</span>
            </div>
            <div class="ep-item-center">
                <div class="ep-item-title">国家高新技术企业</div>
                <div class="ep-item-sub">高新技术企业是指在《国家重点支持的高新技术领域》内，持续进行研究开发与技术成果转化，形成企业核心自主知识产权，
                    并以此为基础开展经营活动，在中国境内（不包括港、澳、台地区）注册一年以上的居民企业。它是知识密集、技术密集的经济实体。
                </div>
                <div style="display: flex;">
                    <div v-if="nowIndex==0" class="ep-item-btn" @click="checkReport()">查看报告</div>
                    <div v-else class="ep-item-btn dis-btn">查看报告</div>
                    <div class="ep-item-btn dis-btn">下载报告</div>
                    <div v-if="nowIndex==0" class="ep-item-btn" @click="gotoPage('Measurement')">重新测评</div>
                    <div v-else class="ep-item-btn" @click="gotoPage('Measurement')">立即测评</div>
                </div>
            </div>
            <div class="block4 flex-col"></div>
            <div class="block5 flex-col" v-if="nowIndex==0">
                <span class="info6">您的得分：</span>
                <div class="outer4">
                    <span class="word14">{{reportList.totalScore}}</span>
                    <span class="txt6">分</span>
                </div>
                <span class="word15">上次测评：{{ reportList.createTime }}</span>
            </div>
            <div v-else class="block5 flex-col">
                <div class="outer4">
                    <span style="color: #FF4A4D;font-size: 32px">未测评</span>
                </div>
            </div>
        </div>

        <div class="ep-item">
            <div class="block2 flex-col">
                <span class="paragraph1">科技型中<br/>小企业</span>
            </div>
            <div class="ep-item-center">
                <div class="ep-item-title">科技型中小企业</div>
                <div class="ep-item-sub">科技型中小企业是指依托一定数量的科技人员从事科学技术研究开发活动，
                    取得自主知识产权并将其转化为高新技术产品或服务，从而实现可持续发展的中小企业。
                </div>
                <div style="display: flex;">
                    <div class="ep-item-btn dis-btn">查看报告</div>
                    <div class="ep-item-btn dis-btn">下载报告</div>
                    <div class="ep-item-btn dis-btn">重新测评</div>
                </div>
            </div>
            <div class="block4 flex-col"></div>
            <div class="block5 flex-col">
                <div class="outer4">
                    <span style="color: #999;font-size: 32px">敬请期待</span>
                </div>
            </div>
        </div>

        <div class="ep-item">
            <div class="block2 flex-col">
                <span class="paragraph1">科技创新<br/>小巨人企业</span>
            </div>
            <div class="ep-item-center">
                <div class="ep-item-title">科技创新小巨人企业</div>
                <div class="ep-item-sub">科技创新小巨人企业是指企业在研究、开发、生产、销售和管理过程中，通过技术创新、
                    管理创新、服务创新或模式创新取得核心竞争力，提供高新技术产品或服务，具有较高成长性或发展潜力巨大的科技创新中小企业。
                </div>
                <div style="display: flex;">
                    <div class="ep-item-btn dis-btn">查看报告</div>
                    <div class="ep-item-btn dis-btn">下载报告</div>
                    <div class="ep-item-btn dis-btn">重新测评</div>
                </div>
            </div>
            <div class="block4 flex-col"></div>
            <div class="block5 flex-col">
                <div class="outer4">
                    <span style="color: #999;font-size: 32px">敬请期待</span>
                </div>
            </div>
        </div>
        <div class="ep-item">
            <div class="block2 flex-col">
                <span class="paragraph1">双软认证</span>
            </div>
            <div class="ep-item-center">
                <div class="ep-item-title">双软认证</div>
                <div class="ep-item-sub">“双软认证”是指软件产品评估和软件企业评估； 企业申请双软认证除了获得软件企业和软件产品的认证资质，
                    同时也是对企业知识产权的一种保护方式，更可以让企业享受国家提供给软件行业的税收优惠政策。
                </div>
                <div style="display: flex;">
                    <div class="ep-item-btn dis-btn">查看报告</div>
                    <div class="ep-item-btn dis-btn">下载报告</div>
                    <div class="ep-item-btn dis-btn">重新测评</div>
                </div>
            </div>
            <div class="block4 flex-col"></div>
            <div class="block5 flex-col">
                <div class="outer4">
                    <span style="color: #999;font-size: 32px">敬请期待</span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {
        getReport,
        getEvalId,
    } from "@/plugins/api/EnterpriseExam";

    export default {
        name: "EnterprisePhysicalExaminationList",
        mounted() {
            this.ReportIfExist()
        },
        data() {
            return {
                oldEvalId: '',
                evalId: '',
                reportList: '',
                nowIndex: 0
            };
        },
        methods: {
// 是否有报告
            async ReportIfExist() {
                let json = await getEvalId();
                if (json.code == 200) {
                    if (json.data) {
                        this.oldEvalId = json.data
                        this.evalId = json.data
                        this.getReportFun();
                    } else {
                        this.oldEvalId = ''
                        this.evalId = ''
                        this.nowIndex = 1
                    }

                }
            },
            // 得到报告
            async getReportFun() {
                let data = {
                    evalId: this.evalId == "" ? this.oldEvalId : this.evalId,
                };
                let json = await getReport(data);
                if (json.code == "200") {
                    this.reportList = json.data;
                    let d = new Date(this.reportList.createTime);
                    let datetime=d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
                    this.reportList.createTime = datetime
                    this.nowIndex = 0;
                } else {
                    this.nowIndex = 1
                }
            },
            checkReport(){
                const {href} = this.$router.resolve({
                    path:'/report-result',
                    query:{
                        id:this.evalId
                    }
                })
                window.open(href,'_blank')
            },
            gotoPage(val) {
                if(this.evalId){
                    this.$router.push({
                        path: 'enterprise-physical-examination',
                        query: {
                            page:val,
                            evalId: this.evalId
                        }
                    })
                }else{
                    this.$router.push({
                        path: 'enterprise-physical-examination',
                        query: {
                            page:val
                        }
                    })
                }

            }
        }
    };
</script>

<style scoped lang="scss">
    .block2 {
        z-index: 195;
        position: relative;
        width: 150px;
        height: 150px;
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url('~@/assets/image/enterprise/logo-bg.png') 100% no-repeat;
    }

    .paragraph1 {
        z-index: 215;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 26px;
        line-height: 37px;
        text-align: center;
    }

    .block4 {
        z-index: 192;
        width: 1px;
        height: 120px;
        border: 1px solid rgba(229, 232, 237, 1);
        margin-top: 16px;
    }

    .block5 {
        z-index: auto;
        width: 152px;
        height: 124px;
        margin: 13px 0 0 71px;
    }

    .info6 {
        z-index: 190;
        width: 70px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        line-height: 14px;
        text-align: left;
        align-self: flex-start;
    }

    .outer4 {
        z-index: 188;
        height: 56px;
        overflow-wrap: break-word;
        text-align: left;
        align-self: flex-start;
        margin-top: 20px;
        font-size: 0;
    }

    .word14 {
        font-size: 56px;
        color: rgba(255, 160, 4, 1);
        line-height: 56px;
    }

    .txt6 {
        font-size: 16px;
        color: rgba(255, 160, 4, 1);
        line-height: 56px;
    }

    .word15 {
        z-index: 189;
        width: 152px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 14px;
        line-height: 14px;
        text-align: left;
        align-self: center;
        margin-top: 20px;
    }

    .flex-col {
        display: flex;
        flex-direction: column;
    }

    .ep-item {
        display: flex;
        padding: 30px;
        background: #fff;
        border-radius: 8px;
        margin-bottom: 20px;
        width: 980px;
    }

    .ep-item-center {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 150px;
        padding: 0 45px 0 40px;
    }


    .ep-item-title {
        font-size: 20px;
        color: #333333;
        font-weight: bold;
    }

    .ep-item-sub {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
    }

    .ep-item-btn {
        width: 88px;
        height: 32px;
        background: rgba(24, 144, 255, 0.04);
        border-radius: 4px;
        border: 1px solid #1890FF;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #1890FF;
        line-height: 32px;
        margin-right: 14px;
        cursor: pointer;
    }

    .ep-item-btn:last-child {
        background: #1890FF;
        color: #fff;
    }

    .dis-btn {
        width: 88px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.04) !important;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25) !important;
    }
</style>
